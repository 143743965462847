// src/pages/GemModels.js
import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, Button, Grid, Card, CardContent, CardMedia, CardActionArea } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import gaming from '../assets/gaming.jpeg';
import advertising from '../assets/advertising.jpeg';
import analytics from '../assets/analytics.jpeg';
import ReactGA from 'react-ga4';

const industries = [
  { id: 'advertising', label: 'Advertising', image: advertising },
  { id: 'gaming', label: 'Gaming', image: gaming },
  { id: 'analytics', label: 'Analytics', image: analytics },
];

const advertisingUseCases = [
  { id: 'image-generation', label: 'Advertisement Image Generation' },
  { id: 'image-scoring', label: 'Advertisement Comparison and Scoring' },
  { id: 'content-generation', label: 'Advertisement Content Generation' },
  { id: 'image-recommendation', label: 'Advertisement Recommendation' },
];

const GemModels = () => {
  const [selectedIndustry, setSelectedIndustry] = useState(null);
  const [selectedUseCase, setSelectedUseCase] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // This will track the page view with the current path
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search});
  }, []);
  

  const handleIndustrySelect = (industry) => {
    setSelectedIndustry(industry);
    setSelectedUseCase(null); // Reset use case when industry is selected
  };

  const handleUseCaseSelect = (useCase) => {
    setSelectedUseCase(useCase);
  };

  const handleGoClick = () => {
    if (selectedIndustry && selectedUseCase) {
      navigate(`/${selectedIndustry.id}/${selectedUseCase.id}`);
    }
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', padding: 2 }}>
      <Container component="main" maxWidth="md">
        <Box mt={4} textAlign="center">
          <Typography component="h1" variant="h4" gutterBottom style={{ color: '#ffffff' }}>
            GEM Ready to Use AI Models
          </Typography>
          <Typography component="h2" variant="h6" gutterBottom style={{ color: '#fffff0' }}>
            Select your Industry
          </Typography>

          <Grid container spacing={2} justifyContent="center">
            {industries.map((industry) => (
              <Grid item key={industry.id}>
                <Card
                  onClick={() => handleIndustrySelect(industry)}
                  sx={{
                    backgroundColor: selectedIndustry?.id === industry.id ? '#90caf9' : '#add8e6', // Darker blue for selected, lighter blue for others
                    borderColor: selectedIndustry?.id === industry.id ? '#00008b' : '#add8e6', // Dark blue border for selected
                    borderWidth: 2,
                    borderStyle: 'solid',
                    cursor: 'pointer',
                    boxShadow: selectedIndustry?.id === industry.id ? '0 0 10px rgba(0, 0, 0, 0.5)' : 'none' // Add shadow for selected
                  }}
                >
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      height="180"
                      image={industry.image}
                      alt={industry.label}
                    />
                    <CardContent>
                      <Typography variant="body2" component="p" style={{ color: '#001f3f' }}>
                        {industry.label}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
          </Grid>

          {selectedIndustry && selectedIndustry.id === 'advertising' && (
            <>
              <Typography component="h2" variant="h6" gutterBottom mt={4} style={{ color: '#fffff0' }}>
                Select Use Case
              </Typography>

              <Grid container spacing={2} justifyContent="center">
                {advertisingUseCases.map((useCase) => (
                  <Grid item key={useCase.id}>
                    <Card
                      onClick={() => handleUseCaseSelect(useCase)}
                      sx={{
                        backgroundColor: selectedUseCase?.id === useCase.id ? '#90caf9' : '#add8e6', // Darker blue for selected, lighter blue for others
                        borderColor: selectedUseCase?.id === useCase.id ? '#00008b' : '#add8e6', // Dark blue border for selected
                        borderWidth: 2,
                        borderStyle: 'solid',
                        cursor: 'pointer',
                        width: 300,
                        boxShadow: selectedUseCase?.id === useCase.id ? '0 0 10px rgba(0, 0, 0, 0.5)' : 'none' // Add shadow for selected
                      }}
                    >
                      <CardContent>
                        <Typography variant="body2" component="p" style={{ color: '#001f3f' }}>
                          {useCase.label}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </>
          )}

          <Box mt={4} display="flex" justifyContent="center">
            <Button
              variant="contained"
              color="warning"
              size="large"
              sx={{ borderRadius: '20px', color: 'black' }}
              disabled={!selectedUseCase}
              onClick={handleGoClick}
            >
              Continue
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default GemModels;
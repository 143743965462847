// src/theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#90caf9',
    },
    secondary: {
      main: '#f48fb1',
    },
    background: {
      default: '#010a12',
      paper: '#1d1d1d',
    },
    text: {
      primary: '#e0e1dd',
    },
  },
});

export default theme;
// src/pages/Home.js
import React from 'react';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Link } from 'react-router-dom';
import { Button, Container, Typography, Box, Grid } from '@mui/material';
import sampleImage from '../assets/about_us.jpg'; // Ensure you have this image in the assets folder



const Home = () => {
    // useEffect(() =>{
    //     ReactGA.pageview(window.location.pathname + window.location.search);
    // },[]);

    useEffect(() => {
        // This will track the page view with the current path
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search});
      }, []);

  return (
    <Container>
      <Box mt={4}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <img src={sampleImage} alt="Sample" style={{ width: '100%', borderRadius: '8px' }} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h4" gutterBottom>
              GEM - General Enterprise Machine Learning
            </Typography>
            <Typography variant="body1" paragraph>
              GEM - General Enterprise Machine Learning, is a proprietary cutting-edge recognition system powered by AI and designed for a wide range of visual recognition applications. Scope is dedicated to continuously enhancing GEM's capabilities, providing businesses of all sizes with a versatile solution to develop their own recognition and visual information systems.
            </Typography>
            <Typography variant="body1" paragraph>
              Reasons You Should Incorporate GEM Into Your Business TODAY:
            </Typography>
            <Typography variant="body1" paragraph component="div">
              <ul>
                <li>Versatile AI Solution</li>
                <li>Wide Range of Applications</li>
                <li>Competitive Edge</li>
                <li>Ease of Use</li>
                <li>Cost-Effective Innovation</li>
                <li>Reliability and Security</li>
                <li>Continuous Improvement</li>
                <li>Scalability for Growth</li>
              </ul>
            </Typography>
            </Grid>
            </Grid>
            <Typography variant="h5" gutterBottom>
              How can a Neural Network help the Advertising Industry?
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>Targeted Advertising:</strong> Neural networks can analyze vast amounts of user data, such as browsing history, purchase behavior, demographics, and interests, to create highly targeted advertising campaigns. By understanding individual preferences and behaviors, advertisers can deliver personalized ads that are more likely to resonate with the audience, leading to higher engagement and conversion rates.
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>Predictive Analytics:</strong> Neural networks can forecast consumer trends and preferences based on historical data, allowing advertisers to anticipate future demand and adjust their marketing strategies accordingly. This predictive capability enables advertisers to stay ahead of market changes and optimize their advertising campaigns for maximum effectiveness.
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>Ad Content Optimization:</strong> Neural networks can analyze the performance of different ad creatives, such as images, videos, and copy, to determine which elements are most effective in driving user engagement and conversions. By continuously optimizing ad content based on real-time feedback, advertisers can improve the effectiveness of their campaigns and achieve better results.
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>Fraud Detection:</strong> Neural networks can detect fraudulent activities, such as click fraud and ad fraud, by analyzing patterns and anomalies in user behavior. By identifying and mitigating fraudulent activities, advertisers can ensure that their advertising budgets are spent efficiently and that their ads reach genuine potential customers.
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>Customer Segmentation:</strong> Neural networks can segment the target audience into distinct groups based on their characteristics and behavior, allowing advertisers to tailor their advertising messages to specific customer segments. By delivering relevant and personalized ads to each segment, advertisers can increase the likelihood of conversion and improve the overall ROI of their advertising campaigns.
            </Typography>
            <Box mt={4} mb={4} display="flex" justifyContent="center" gap={2}>
              <Button component={Link} to="/login" variant="contained" color="primary">
                Login
              </Button>
              <Button component={Link} to="/signup" variant="outlined" color="primary">
                Signup
              </Button>
            </Box>
       
      </Box>
    </Container>
  );
};

export default Home;
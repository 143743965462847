// src/components/Header.js
import React, { useContext } from 'react';
import { AppBar, Toolbar, Typography, Box, Button } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import logo from '../assets/logo.png'; // Make sure to add a logo image in the assets folder

const Header = () => {
  const { auth, logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogoClick = () => {
    if (auth) {
      navigate('/dashboard');
    } else {
      navigate('/');
    }
  };

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  return (
    <AppBar position="static" style={{ background: '#001f3f', width: '100%' }}>
      <Toolbar>
        <Box display="flex" alignItems="center" width="100%">
          <Box flexBasis="20%" display="flex" alignItems="center">
            <img 
              src={logo} 
              alt="Logo" 
              style={{ height: 40, marginRight: 10, cursor: 'pointer' }} 
              onClick={handleLogoClick} 
            />
          </Box>
          
          <Typography variant="h6" style={{ flexGrow: 1, textAlign: 'center' }}>
            Scope AI - GEM
          </Typography>
        
          <Box flexBasis="20%" display="flex" justifyContent="flex-end">
            {auth ? (
              <Button color="inherit" variant="outlined" onClick={handleLogout}>Logout</Button>
            ) : (
              <Link to="/login" style={{ textDecoration: 'none', color: 'inherit' }}>
                <Button color="inherit" variant="outlined">Login</Button>
              </Link>
            )}
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
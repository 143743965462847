// src/pages/Support.js
import React, { useEffect } from 'react';
import { Container, Typography, Box } from '@mui/material';
import ReactGA from 'react-ga4';

const Support = () => {
  useEffect(() => {
    // This will track the page view with the current path
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search});
  }, []);

  return (
    <Container component="main" maxWidth="md">
      <Box mt={4}>
        <Typography component="h1" variant="h4">
          Support / Help / Docs
        </Typography>
        <Typography component="p" variant="body1" mt={2}>
          Here you can find support, help, and documentation.
        </Typography>
      </Box>
    </Container>
  );
};

export default Support;
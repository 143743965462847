import React, { useState, useContext, useEffect } from 'react';
import { TextField, Button, Container, Typography, Box } from '@mui/material';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import { API_HOST } from '../config/config';
import ReactGA from 'react-ga4';


const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [validationError, setValidationError] = useState('');
  const [showResendVerification, setShowResendVerification] = useState(false);
  const navigate = useNavigate();
  const { login } = useContext(AuthContext);

  
  useEffect(() => {
    // This will track the page view with the current path
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search});
  }, []);  

  const handleSubmit = async (e) => {
    e.preventDefault();
    setValidationError('');
    setError('');
    setMessage('');
    setShowResendVerification(false);

    if (!username || !password) {
      setValidationError('Username and Password are required');
      return;
    }

    try {
      const response = await axios.post(`${API_HOST}/api/v1/auth/login`, new URLSearchParams({
        username,
        password,
        grant_type: '',
        scope: '',
        client_id: '',
        client_secret: ''
      }), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });
      console.log('User logged in:', response.data);
      login(response.data.access_token)
      navigate('/dashboard');
      
    } catch (err) {
      if (err.response && err.response.data && err.response.data.detail) {
        setError(err.response.data.detail);
        if (err.response.data.detail === 'Email is not verified. Please check your email.') {
          setShowResendVerification(true);
        }
      } else {
        setError('An error occurred. Please try again.');
      }
    }
  };

  const handleResendVerification = async () => {
    try {
      await axios.post(`${API_HOST}/api/v1/auth/resend_verification`,null, {
        params: { username },
        headers: { 'accept': 'application/json' }
      });
      setError('')
      setMessage('Verification email has been resent. Please check your email.')
      // alert('Verification email has been resent. Please check your email.');
    } catch (err) {
      if (err.response && err.response.data && err.response.data.detail) {
        setError(err.response.data.detail);
      }
      else{
        setError('Failed to resend verification email. Please try again.');
      }
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Typography component="h1" variant="h5">Login</Typography>
      {validationError && <Typography color="error" variant="body2">{validationError}</Typography>}
      {error && <Typography color="error" variant="body2">{error}</Typography>}
      {message && <Typography color={'green'} variant='body2'>{message}</Typography>}
      <form noValidate onSubmit={handleSubmit}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="username"
          label="Username"
          name="username"
          autoComplete="username"
          autoFocus
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Box mt={2}>
              <Button type="submit" fullWidth variant="contained" color="primary">
                Sign In
              </Button>
            </Box>
        {/* <Box mt={2}>
          <Button 
            type="submit" 
            fullWidth 
            variant="contained" 
            color="primary"
            sx={{
              background: 'linear-gradient(to right, #1976D2, #0D47A1)',
              color: '#fff',
              fontWeight: 'bold',
              '&:hover': {
                background: 'linear-gradient(to right, #0D47A1, #0B3954)',
              },
            }}
          >
            Sign In
          </Button>
        </Box> */}
      </form>
      {showResendVerification && (
        <Box mt={2}>
          <Button
            fullWidth
            variant='outlined'
            color='secondary'
            onClick={handleResendVerification}
          >
            Resend Verification Email
          </Button>
        </Box>
      )}
      <Box mt={2}>
        <Link to="/forgot-password" style={{ textDecoration: 'none', color: 'inherit' }}>
          <Typography variant="body2" color="primary">Forgot Password?</Typography>
        </Link>
      </Box>
      <Box mt={2}>
        <Typography component="h2" variant="h6" style={{ display: 'flex', alignItems: 'center' }}>
          <Link to="/signup" style={{ textDecoration: 'none', color: 'inherit', marginRight: '20px' }}>
            New User?
          </Link>
          <Link to="/signup" style={{ textDecoration: 'none', color: 'inherit', marginRight: '10px' }}>
            <Button fullWidth color="primary" variant="outlined" style={{ fontSize: '0.9rem' }}>
              Sign Up
            </Button>
          </Link>
        </Typography>
      </Box>
    </Container>
  );
};

export default Login;
// src/pages/Dashboard.js
import React from 'react';
import { useEffect } from 'react';
import { Container, Typography, Box, Button, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';

const Dashboard = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // This will track the page view with the current path
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search});
  }, []);
  

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <Container component="main" maxWidth="md">
      <Box mt={4}>
        <Typography component="h1" variant="h4" gutterBottom>
          Welcome to the GEM!
        </Typography>
        <Typography component="p" variant="body1" mt={2} gutterBottom>
          You have successfully logged in. This is your dashboard.
        </Typography>
        <Grid container spacing={3} mt={4}>
          <Grid item xs={12} sm={6}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => handleNavigation('/profile')}
            >
              Profile
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => handleNavigation('/build-model')}
            >
              Build Model
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => handleNavigation('/gem-models')}
            >
              GEM Models
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => handleNavigation('/support')}
            >
              Support / Docs
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default Dashboard;
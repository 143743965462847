import React, { useState, useEffect } from 'react';
import { TextField, Button, Container, Typography, Box } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { API_HOST } from '../config/config';
import ReactGA from 'react-ga4';
const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [passwordHelperText, setPasswordHelperText] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [step, setStep] = useState(1); // Step 1: Request OTP, Step 2: Enter OTP and New Password
  const navigate = useNavigate();

  useEffect(() => {
    // This will track the page view with the current path
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search});
  }, []);
  

  const handleRequestOtp = async () => {
    try {
      await axios.post(`${API_HOST}/api/v1/auth/request_password_reset`, null, {
        params: { email },
        headers: { 'accept': 'application/json' }
      });
      setStep(2);
    } catch (err) {
      if (err.response && err.response.data && err.response.data.detail) {
        setError(err.response.data.detail);
      } else {
        setError('Failed to send OTP. Please try again.');
      }
    }
  };

  const validatePassword = (password) => {
    const re = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return re.test(password);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    if (validatePassword(e.target.value)) {
      setPasswordError(false);
      setPasswordHelperText('');
    } else {
      setPasswordError(true);
      setPasswordHelperText('Password must be at least 8 characters long, contain one uppercase letter, one number, and one special character');
    }
  };

  const handleResetPassword = async () => {
    try {
      await axios.post(`${API_HOST}/api/v1/auth/reset_password`, null, {
        params: { otp, new_password: password },
        headers: { 'accept': 'application/json' }
      });
      setError('')
      setSuccess('Password has been successfully reset! Redirecting to login...');
      setTimeout(() => {
        navigate('/login');
      }, 3000); // Redirect after 3 seconds
    } catch (err) {
      if (err.response && err.response.data && err.response.data.detail) {
        setError(err.response.data.detail);
      } else {
        setError('Failed to reset password. Please try again.');
      }
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Typography component="h1" variant="h5">
        {step === 1 ? 'Forgot Password' : 'Reset Password'}
      </Typography>
      {error && <Typography color="error" variant="body2">{error}</Typography>}
      {success && <Typography color="primary" variant="body2">{success}</Typography>}
      {step === 1 && (
        <Box>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Box mt={2}>
            <Button onClick={handleRequestOtp} fullWidth variant="contained" color="primary">Request OTP</Button>
          </Box>
        </Box>
      )}
      {step === 2 && (
        <Box>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="otp"
            label="OTP"
            name="otp"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="New Password"
            type="password"
            id="password"
            autoComplete="new-password"
            value={password}
            onChange={handlePasswordChange}
            error={passwordError}
            helperText={passwordHelperText}
          />
          <Box mt={2}>
            <Button onClick={handleResetPassword} fullWidth variant="contained" color="primary">Reset Password</Button>
          </Box>
        </Box>
      )}
    </Container>
  );
};

export default ForgotPassword;
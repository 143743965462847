import React from 'react';
import { Box, Typography } from '@mui/material';

const Footer = () => {
  return (
    <Box component="footer" mt={4} sx={{ py: 3, px: 2, mt: 4, backgroundColor: '#001f3f', color: 'white', width: '100%' }}>
  <Typography variant="body2" align="center">
    &copy; 2024{' '}
    <a href="https://scopetech.ai" target="_blank" rel="noopener noreferrer" style={{ color: 'inherit', textDecoration: 'underline' }}>
      Scope AI Corp
    </a>. All rights reserved.
  </Typography>
</Box>
  );
};

export default Footer;
// src/pages/BuildModel.js
import React, { useEffect } from 'react';
import { Container, Typography, Box, Button, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import ReactGA from 'react-ga4';
// import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const BuildModel = () => {
  useEffect(() => {
    // This will track the page view with the current path
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search});
  }, []);

  return (
    <Box sx={{ display: 'flex' }}>
      <Container component="main" maxWidth="md">
        <Box mt={4}>
          <Typography component="h1" variant="h4" gutterBottom>
            Build your own AI Models
          </Typography>

          <FormControl fullWidth margin="normal">
            <InputLabel id="industry-label">Select the industry</InputLabel>
            <Select labelId="industry-label" id="industry" label="Select the industry">
              <MenuItem value="advertising">Advertising</MenuItem>
              <MenuItem value="gaming">Gaming</MenuItem>
              <MenuItem value="finance">Finance</MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <InputLabel id="use-case-label">What is the use case</InputLabel>
            <Select labelId="use-case-label" id="use-case" label="What is the use case">
              <MenuItem value="object-detection">Object Detection</MenuItem>
              <MenuItem value="image-classification">Image Classification</MenuItem>
              <MenuItem value="text-analysis">Text Analysis</MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <InputLabel id="data-type-label">Input Data Type</InputLabel>
            <Select labelId="data-type-label" id="data-type" label="Input Data Type">
              <MenuItem value="images">Images</MenuItem>
              <MenuItem value="videos">Videos</MenuItem>
              <MenuItem value="text">Text</MenuItem>
            </Select>
            <Typography variant="body2" mt={1}>Input Required as per use case: Images</Typography>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <InputLabel id="model-label">Select Model (Optional)</InputLabel>
            <Select labelId="model-label" id="model" label="Select Model (Optional)">
              <MenuItem value="yolo">Yolo</MenuItem>
              <MenuItem value="resnet">ResNet</MenuItem>
              <MenuItem value="bert">BERT</MenuItem>
            </Select>
            <Typography variant="body2" mt={1}>Recommended by GEM: Yolo</Typography>
          </FormControl>

          {/* <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            border="1px dashed grey"
            borderRadius="8px"
            p={4}
            mt={4}
          >
            <CloudUploadIcon fontSize="large" color="action" />
            <Typography variant="body2" color="textSecondary" align="center">
              Click to browse or drag and drop your files
            </Typography>
            <input
              type="file"
              style={{
                opacity: 0,
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                cursor: 'pointer',
              }}
            />
          </Box> */}

          <Box mt={4} mb={4} display="flex" justifyContent="center">
            <Button variant="contained" color="primary" size="large">
              Start Training
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default BuildModel;
import React, { useState, useContext, useEffect } from 'react';
import { Container, Typography, Box, Button, Grid, Card, CardMedia, CardContent, TextField, Switch, FormControlLabel, Checkbox, LinearProgress } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import { API_HOST } from '../config/config';
import ReactGA from 'react-ga4';

const ComparisonScoring = () => {
  const [images, setImages] = useState([]);
  const [results, setResults] = useState([]);
  const [summary, setSummary] = useState('');
  const [headline, setHeadline] = useState('');
  const [useOpenAI, setUseOpenAI] = useState(false);
  const [numImages, setNumImages] = useState(3);
  const [showAll, setShowAll] = useState(false);
  const { auth } = useContext(AuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    // This will track the page view with the current path
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search});
  }, []);

  const handleImageChange = (e) => {
    setImages([...e.target.files]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setResults([]);
    setSummary('');

    if (!auth) {
      navigate('/login');
      return;
    }

    const formData = new FormData();
    images.forEach((image) => {
      formData.append('files', image);
    });

    try {
      const response = await axios.post(
        `${API_HOST}/api/v1/ml/find_best_image/`,
        formData,
        {
          params: {
            headline: headline,
            use_openai: useOpenAI
          },
          headers: {
            'Authorization': `Bearer ${auth}`,
            'Content-Type': 'multipart/form-data'
          }
        }
      );

      if (useOpenAI) {
        setResults(Object.entries(response.data.scores).map(([filename, score]) => ({ filename, score })));
        setSummary(response.data.summary);
      } else {
        setResults(response.data.results);
        setSummary('');
      }
    } catch (error) {
      setError('Failed to generate scores. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const displayedResults = showAll ? results : results.slice(0, numImages);

  return (
    <Container component="main" maxWidth="md">
      <Box mt={4}>
        <Typography component="h1" variant="h4" gutterBottom>
          Advertisement Comparison and Scoring
        </Typography>
        {error && (
          <Typography color="error" variant="body2">
            {error}
          </Typography>
        )}
        <form onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="headline"
            label="Headline"
            name="headline"
            autoComplete="headline"
            autoFocus
            value={headline}
            onChange={(e) => setHeadline(e.target.value)}
          />
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            border="2px dashed grey"
            borderRadius="8px"
            p={4}
            mt={2}
            style={{ cursor: 'pointer' }}
            onClick={() => document.getElementById('upload-multiple-images').click()}
          >
            <Typography variant="body2" color="textSecondary" align="center">
              Click to browse or drag and drop your files
            </Typography>
            <input
              accept="image/*"
              id="upload-multiple-images"
              multiple
              type="file"
              onChange={handleImageChange}
              style={{ display: 'none' }}
            />
          </Box>
          {images.length > 0 && (
            <Box mt={2}>
              <Typography variant="body2" color="textSecondary">
                Uploaded Files:
              </Typography>
              {images.map((image, index) => (
                <Typography key={index} variant="body2" color="textSecondary">
                  {image.name}
                </Typography>
              ))}
            </Box>
          )}
          <Box mt={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={useOpenAI}
                  onChange={(e) => setUseOpenAI(e.target.checked)}
                  color="primary"
                />
              }
              label="Gem Pro"
            />
          </Box>
          <Box mt={2}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="numImages"
              label="Number of Top Images to Display"
              name="numImages"
              type="number"
              value={numImages}
              onChange={(e) => setNumImages(e.target.value)}
              disabled={showAll}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={showAll}
                  onChange={(e) => setShowAll(e.target.checked)}
                  color="primary"
                />
              }
              label="Show All Images"
            />
          </Box>
          <Box mt={4} mb={4}>
            <Button type="submit" variant="contained" color="warning" fullWidth disabled={loading} sx={{ borderRadius: '20px', color: '#000000' }}>
              {loading ? 'Generating...' : 'Generate Image Relevance Scores'}
            </Button>
          </Box>
        </form>
        {loading && <LinearProgress />}
        {results.length > 0 && (
          <Box mt={4}>
            <Typography component="h2" variant="h6" gutterBottom color={'#26D608'}>
              Images Relevance Score
            </Typography>
            <Grid container spacing={2} justifyContent="center">
              {displayedResults.map((result, index) => {
                const imageObj = images.find(img => img.name === (useOpenAI ? result.filename : result.file_path?.split('/').pop()));
                const imageUrl = imageObj ? URL.createObjectURL(imageObj) : null;

                return (
                  <Grid item key={index}>
                    <Card>
                      {imageUrl ? (
                        <CardMedia
                          component="img"
                          height="180"
                          image={imageUrl}
                          alt={result.filename || result.file_path}
                        />
                      ) : (
                        <Typography variant="body1" color="error" component="p">
                          Generate Scores Again !!!
                        </Typography>
                      )}
                      <CardContent>
                        <Typography variant="body1" color="textSecondary" component="p" fontSize={20}>
                          Score: {(result.score * 100).toFixed(2)}
                        </Typography>
                        {useOpenAI ? (
                          <Typography variant="body1" color="textSecondary" component="p">
                            {result.filename}
                          </Typography>
                        ) : (
                          <Typography variant="body1" color="textSecondary" component="p">
                            {result.file_name}
                          </Typography>
                        )}
                      </CardContent>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
            {useOpenAI && summary && (
              <Box mt={4}>
                <Typography component="h2" variant="h6" gutterBottom color='#26D608'>
                  Summary
                </Typography>
                <Typography variant="body1">
                  {summary}
                </Typography>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default ComparisonScoring;
import React, { useState, useContext, useEffect } from 'react';
import { Container, TextField, Button, Typography, Box, Grid, Card, CardContent, FormControlLabel, Checkbox, LinearProgress } from '@mui/material';
import axios from 'axios';
import AuthContext from '../context/AuthContext';
import * as XLSX from 'xlsx';
import { API_HOST } from '../config/config';
import ReactGA from 'react-ga4';

const ContentGeneration = () => {
  const { auth } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    descr: 'Ad for a new VR Headset, which has inbuilt games',
    language: 'English',
    target_audience: 'Adults of age 18-30',
    text_tone: 'Informal',
    brand_name: 'BrandX',
    n_headline: 6,
    n_ad_text: 6,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [headlines, setHeadlines] = useState([]);
  const [adTexts, setAdTexts] = useState([]);
  const [metaData, setMetaData] = useState({ keywords: [], hashtags: [], tags: [] });
  const [uploadImage, setUploadImage] = useState(false);
  const [image, setImage] = useState(null);

  useEffect(() => {
    // This will track the page view with the current path
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search});
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === 'n_headline' || name === 'n_ad_text') {
      const intValue = Math.max(2, parseInt(value));
      setFormData({ ...formData, [name]: intValue });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleImageChange = (event) => {
    setImage(event.target.files[0]);
  };

  const handleCheckboxChange = (event) => {
    setUploadImage(event.target.checked);
    setImage(null);  // Clear the image if checkbox is unchecked
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError('');
    setHeadlines([]);
    setAdTexts([]);
    setMetaData({ keywords: [], hashtags: [], tags: [] });

    const url = uploadImage ? `${API_HOST}/api/v1/ml/generate_ad_content_using_image/` : `${API_HOST}/api/v1/ml/generate_ad_content/`;
    const data = new FormData();
    
    for (const key in formData) {
      data.append(key, formData[key]);
    }
    
    if (uploadImage && image) {
      data.append('image', image);
    }

    try {
      const response = await axios.post(url, data, {
        headers: {
          'Authorization': `Bearer ${auth}`,
          'Content-Type': 'multipart/form-data'
        }
      });
      setHeadlines(response.data.ad_headline);
      setAdTexts(response.data.ad_text);
      setMetaData(response.data.meta_data);
    } catch (err) {
      setError('Failed to generate ad content. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleExport = () => {
    const wb = XLSX.utils.book_new();
    const wsData = [
      ['Headlines', 'Ad Texts', 'Keywords', 'Hashtags', 'Tags'],
      ...headlines.map((headline, index) => [
        headline,
        adTexts[index] || '',
        metaData.keywords.join(', '),
        metaData.hashtags.join(', '),
        metaData.tags.join(', ')
      ]),
    ];
    const ws = XLSX.utils.aoa_to_sheet(wsData);
    XLSX.utils.book_append_sheet(wb, ws, 'Ad Content');
    XLSX.writeFile(wb, 'AdContent.xlsx');
  };

  return (
    <Container component="main" maxWidth="md">
      <Box mt={4}>
        <Typography component="h1" variant="h4" gutterBottom color="#ffffff">
          Generate Advertisement Content
        </Typography>
        {error && (
          <Typography color="error" variant="body2">
            {error}
          </Typography>
        )}
        <form onSubmit={handleSubmit}>
          <TextField
            name="descr"
            label="Description"
            value={formData.descr}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            name="language"
            label="Language"
            value={formData.language}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            name="target_audience"
            label="Target Audience"
            value={formData.target_audience}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            name="text_tone"
            label="Text Tone"
            value={formData.text_tone}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            name="brand_name"
            label="Brand Name"
            value={formData.brand_name}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            name="n_headline"
            label="No. of Headlines"
            type="number"
            value={formData.n_headline}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            required
            inputProps={{ min: 2 }}
          />
          <TextField
            name="n_ad_text"
            label="No. of Ad Texts"
            type="number"
            value={formData.n_ad_text}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            required
            inputProps={{ min: 2 }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={uploadImage}
                onChange={handleCheckboxChange}
                color="primary"
              />
            }
            label="Upload Image"
          />
          {uploadImage && (
            <Box mt={2}>
              <input type="file" accept="image/*" onChange={handleImageChange} />
            </Box>
          )}
          <Box mt={4} mb={4}>
            <Button type="submit" variant="contained" color="warning" fullWidth disabled={loading} sx={{ borderRadius: '20px', color: '#000000' }}>
              {loading ? 'Generating...' : 'Generate Ad Content'
              }
            </Button>
          </Box>
        </form>
        {loading && <LinearProgress/>}
        {(headlines.length > 0 || adTexts.length > 0) && (
          <Box mt={4} display={'flex'} justifyContent={'right'}>
            <Button variant="contained" color="primary" onClick={handleExport}>
              Export
            </Button>
          </Box>
        )}

        {headlines.length > 0 && (
          <Box mt={0}>
            <Typography component="h2" variant="h5" gutterBottom color="#26D608">
              Headlines
            </Typography>
            <Grid container spacing={2}>
              {headlines.map((headline, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Card sx={{ backgroundColor: '#37474F', height: 80}}>
                    <CardContent>
                      <Typography variant="body1" color="#ffffff">
                        {headline}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
        
        {adTexts.length > 0 && (
          <Box mt={4}>
            <Typography component="h2" variant="h5" gutterBottom color="#26D608">
              Advertisement Texts
            </Typography>
            <Grid container spacing={2}>
              {adTexts.map((adText, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Card sx={{ backgroundColor: '#37474F', height: 350 }}>
                    <CardContent>
                      <Typography variant="body1" color="#ffffff">
                        {adText}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}

        {metaData.keywords.length > 0 && (
          <Box mt={4}>
            <Typography component="h2" variant="h5" gutterBottom color="#26D608">
              Keywords
            </Typography>
            <Grid container spacing={2}>
              {metaData.keywords.map((keyword, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Card sx={{ backgroundColor: '#37474F', height: 60 }}>
                    <CardContent>
                      <Typography variant="body1" color="#ffffff">
                        {keyword}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}

        {metaData.hashtags.length > 0 && (
          <Box mt={4}>
            <Typography component="h2" variant="h5" gutterBottom color="#26D608">
              Hashtags
            </Typography>
            <Grid container spacing={2}>
              {metaData.hashtags.map((hashtag, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Card sx={{ backgroundColor: '#37474F', height: 60 }}>
                    <CardContent>
                      <Typography variant="body1" color="#ffffff">
                        {hashtag}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}

        {metaData.tags.length > 0 && (
          <Box mt={4}>
            <Typography component="h2" variant="h5" gutterBottom color="#26D608">
              Tags
            </Typography>
            <Grid container spacing={2}>
              {metaData.tags.map((tag, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Card sx={{ backgroundColor: '#37474F', height: 60 }}>
                    <CardContent>
                      <Typography variant="body1" color="#ffffff">
                        {tag}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}

        {metaData.tags.length > 0 && (
        <Box mt={4}>
          <Typography component="h2" variant="h5" gutterBottom color="#26D608">
                Meta data
          </Typography>
        </Box>
        )}
        
        {metaData.keywords.length > 0 && (
          <Box mt={2}>
            <Typography variant="body1" color="#ffffff">
              {metaData.keywords.join(', ')}
            </Typography>
          </Box>
        )}

        {metaData.hashtags.length > 0 && (
          <Box mt={1}>
            <Typography variant="body1" color="#ffffff">
              {metaData.hashtags.join(', ')}
            </Typography>
          </Box>
        )}

        {metaData.tags.length > 0 && (
          <Box mt={1}>
            <Typography variant="body1" color="#ffffff">
              {metaData.tags.join(', ')}
            </Typography>
          </Box>
        )}

        {(headlines.length > 0 || adTexts.length > 0) && (
          <Box mt={4} display={'flex'} justifyContent={'centre'}>
            <Button fullWidth variant="contained" color="primary" onClick={handleExport}>
              Export to Excel
            </Button>
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default ContentGeneration;
import React, { useState, useContext, useEffect } from 'react';
import { Container, TextField, Button, Typography, Box, FormControlLabel, Switch, Grid, LinearProgress } from '@mui/material';
import { SketchPicker } from 'react-color';
import axios from 'axios';
import AuthContext from '../context/AuthContext';
import { API_HOST } from '../config/config';
import ReactGA from 'react-ga4';

const GenerateAd = () => {
  const { auth } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    brand_name: 'BrandX',
    actionword_color: '#FFFF00',
    text_tone: 'Informative',
    image_size: '1024x1024',
    body_color: '#76F6D9',
    filename: '',
    brand_colors: '#000000,#FFFFFF',
    text: 'Get the lowest interest rates of up to 4.99%',
    target_audience: 'General Audience',
    action_word: 'Call Now',
    headline: 'Best Car Insurance Rates',
    format: 'landscape',
    headline_color: '#B6F873',
    language: 'English',
    website_link: 'www.example.com',
    use_openai: false
  });
  
  const [adImageUrl, setAdImageUrl] = useState(null);
  const [localAdImage, setLocalAdImage] = useState(null);
  const [adHeadline, setAdHeadline] = useState('');
  const [adText, setAdText] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    // This will track the page view with the current path
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search});
  }, []);

  const handleColorChange = (color, field) => {
    setFormData({ ...formData, [field]: color.hex });
  };

  const handleSwitchChange = (event) => {
    setFormData({ ...formData, use_openai: event.target.checked });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError('');
    setAdImageUrl(null);
    setLocalAdImage(null);

    const form = new FormData();
    for (const [key, value] of Object.entries(formData)) {
      form.append(key, value);
    }

    try {
      const response = await axios.post(
        `${API_HOST}/api/v1/ml/generate_ad/`,
        form,
        {
          params: { use_openai: formData.use_openai },
          headers: {
            'Authorization': `Bearer ${auth}`,
            'Content-Type': 'multipart/form-data'
          }
        }
      );
      setAdImageUrl(response.data.ad_image_url);
      setLocalAdImage(`${API_HOST}/api/v1/ml/files/${response.data.ad_image_path}`);
      setAdHeadline(response.data.ad_headline);
      setAdText(response.data.ad_text);
    } catch (err) {
      console.log(err);
      setError('Failed to generate advertisement. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container component="main" maxWidth="md">
      <Box mt={4}>
        <Typography component="h1" variant="h4" gutterBottom color="#ffffff">
          Generate Advertisement
        </Typography>
        {/* {loading && <LinearProgress />} */}
        {error && (
          <Typography color="error" variant="body2">
            {error}
          </Typography>
        )}
        <form onSubmit={handleSubmit}>
          <TextField
            name="headline"
            label="Headline"
            value={formData.headline}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            name="text"
            label="Text"
            value={formData.text}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            name="brand_name"
            label="Brand Name"
            value={formData.brand_name}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            name="action_word"
            label="Action Word"
            value={formData.action_word}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            name="language"
            label="Language"
            value={formData.language}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            name="website_link"
            label="Website Link"
            value={formData.website_link}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            name="target_audience"
            label="Target Audience"
            value={formData.target_audience}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            name="text_tone"
            label="Text Tone"
            value={formData.text_tone}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            name="image_size"
            label="Image Size"
            value={formData.image_size}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            name="format"
            label="Image Format"
            value={formData.format}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            name="filename"
            label="File Name"
            value={formData.filename}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <FormControlLabel
            control={
              <Switch
                checked={formData.use_openai}
                onChange={handleSwitchChange}
                name="use_openai"
                color="primary"
              />
            }
            label="Use OpenAI for Text editing on image"
          />
          {!formData.use_openai && (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <Typography component="h2" variant="h6">
                  Headline Color
                </Typography>
                <SketchPicker
                  color={formData.headline_color}
                  onChangeComplete={(color) => handleColorChange(color, 'headline_color')}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography component="h2" variant="h6">
                  Body Color
                </Typography>
                <SketchPicker
                  color={formData.body_color}
                  onChangeComplete={(color) => handleColorChange(color, 'body_color')}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography component="h2" variant="h6">
                  Action Word Color
                </Typography>
                <SketchPicker
                  color={formData.actionword_color}
                  onChangeComplete={(color) => handleColorChange(color, 'actionword_color')}
                />
              </Grid>
            </Grid>
          )}
          <Box mt={4} mb={4}>
            <Button type="submit" variant="contained" color="warning" fullWidth disabled={loading} sx={{ borderRadius: '20px', color: '#000000' }}>
              {loading ? 'Generating...' : 'Generate Advertisement'}
            </Button>
          </Box>
        </form>
        {loading && <LinearProgress />}
        {adHeadline && (
          <Typography component="h2" variant="h6" fontSize={35} gutterBottom color={'#26D608'}>
          Result
        </Typography>
        )}
        {adHeadline && (
          <Typography component="h2" variant="h6" color= {formData.headline_color} gutterBottom fontSize={25}>
            Headline: {adHeadline}
          </Typography>
        )}
        {adText && (
          <Typography component="p" variant="body1" color= {formData.body_color} gutterBottom fontSize={20}>
            Text: {adText}
          </Typography>
        )}
        {adText && (  
          <Typography component="p" variant="body1" color= {formData.actionword_color} gutterBottom fontSize={20}>
            Action Word: {formData.action_word}
          </Typography>
        )}
        {adImageUrl && (
          <Box mt={4} mb={4} display="flex" justifyContent="center">
            <img src={adImageUrl} alt="Generated Advertisement" style={{ maxWidth: '100%' }} />
          </Box>
        )}
        {localAdImage && (
          <Box mt={4} mb={4} display="flex" justifyContent="center">
            <img src={localAdImage} alt="Generated Advertisement" style={{ maxWidth: '100%' }} />
          </Box>
        )}
       
      </Box>
    </Container>
  );
};

export default GenerateAd;
import React, { useContext, useEffect } from 'react';
import ReactGA from 'react-ga4';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme';
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import { AuthProvider } from './context/AuthContext';
import AuthContext from './context/AuthContext';
import Login from './pages/login';
import Signup from './pages/signup';
import Home from './pages/home';
import Dashboard from './pages/dashboard';
import GemModels from './pages/GemModel';
import BuildModel from './pages/BuildModel';
import Profile from './pages/profile';
import Support from './pages/support';
import ComparisonScoring from './pages/ImageScoring';
import GenerateAd from './pages/GenerateAd';
import GenerateRecommendation from './pages/ImageRecommendation';
import ContentGeneration from './pages/ContentGeneration';
import ForgotPassword from './pages/ForgotPassword';


function ProtectedRoute({ children }) {
  const { auth } = useContext(AuthContext);

  if (auth === null) {
    // Show a loading indicator or splash screen while checking authentication
    return <div>Loading...</div>;
  }

  return auth ? children : <Navigate to="/login" />;
}

function App() {
  
  useEffect(() => {
    // This will track the page view with the current path
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search});
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
        <div className="app-container">
          <Router>
            <Header />
            <div className="main-content">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/home" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route
                  path="/dashboard"
                  element={
                    <ProtectedRoute>
                      <Dashboard />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/profile"
                  element={
                    <ProtectedRoute>
                      <Profile />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/build-model"
                  element={
                    <ProtectedRoute>
                      <BuildModel />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/gem-models"
                  element={
                    <ProtectedRoute>
                      <GemModels />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/support"
                  element={
                    <ProtectedRoute>
                      <Support />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/advertising/image-scoring"
                  element={
                    <ProtectedRoute>
                      <ComparisonScoring />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/advertising/image-generation"
                  element={
                    <ProtectedRoute>
                      <GenerateAd />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/advertising/image-recommendation"
                  element={
                    <ProtectedRoute>
                      <GenerateRecommendation />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/advertising/content-generation"
                  element={
                    <ProtectedRoute>
                      <ContentGeneration />
                    </ProtectedRoute>
                  }
                />
              </Routes>
            </div>
            <Footer />
          </Router>
        </div>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
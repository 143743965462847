import React, { useState, useEffect } from 'react';
import { TextField, Button, Container, Typography, Box } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { API_HOST } from '../config/config';
import ReactGA from 'react-ga4';

const Signup = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [rePassword, setRePassword] = useState('');
  const [error, setError] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [emailHelperText, setEmailHelperText] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [passwordHelperText, setPasswordHelperText] = useState('');
  const [rePasswordError, setRePasswordError] = useState(false);
  const [rePasswordHelperText, setRePasswordHelperText] = useState('');
  const [nameError, setNameError] = useState(false);
  const [usernameError, setUsernameError] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false); 
  const navigate = useNavigate();

  // useEffect(() =>{
  //   ReactGA.pageview(window.location.pathname);
  // },[]);
  useEffect(() => {
    // This will track the page view with the current path
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search});
  }, []);
  
  
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if fields are filled
    if (!name && !username && !email && !password) {
      setNameError(true);
      setUsernameError(true);
      setEmailError(true);
      setPasswordError(true);
      setError('All Fields are required');
      return;
    }

    if (!name) {
      setNameError(true);
      setError('Name is required');
      return;
    } else {
      setNameError(false);
    }

    if (!email) {
      setEmailError(true);
      setError('Email is required');
      return;
    } else {
      setEmailError(false);
    }

    if (!username) {
      setUsernameError(true);
      setError('Username is required');
      return;
    } else {
      setUsernameError(false);
    }

    if (!password) {
      setPasswordError(true);
      setError('Password is required');
      return;
    } else {
      setPasswordError(false);
    }

    // Check Validations
    if (!validateEmail(email)) {
      setEmailError(true);
      setEmailHelperText('Invalid email address');
      return;
    } else {
      setEmailError(false);
    }

    if (!validatePassword(password)) {
      setPasswordError(true);
      setPasswordHelperText('Password must be at least 8 characters long, contain one uppercase letter, one number, and one special character');
      return;
    }

    if (password !== rePassword) {
      setRePasswordError(true);
      setRePasswordHelperText('Passwords do not match');
      return;
    }

    try {
      const response = await axios.post(`${API_HOST}/api/v1/auth/signup`, {
        name,
        email,
        username,
        password,
      });
      console.log('User registered:', response.data);
      setIsRegistered(true); // Set registration status to true
      setTimeout(() => {
        navigate('/login');
      }, 30000); // Redirect after 30 seconds
    } catch (err) {
      if (err.response && err.response.data && err.response.data.detail) {
        setError(err.response.data.detail);
      } else {
        setError('An error occurred. Please try again.');
      }
    }
  };

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const validatePassword = (password) => {
    const re = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&_])[A-Za-z\d@$!%*?&_]{8,}$/;
    return re.test(password);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (validateEmail(e.target.value)) {
      setEmailError(false);
      setEmailHelperText('');
    } else {
      setEmailError(true);
      setEmailHelperText('Invalid email address');
    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    if (validatePassword(e.target.value)) {
      setPasswordError(false);
      setPasswordHelperText('');
    } else {
      setPasswordError(true);
      setPasswordHelperText('Password must be at least 8 characters long, contain one uppercase letter, one number, and one special character');
    }
  };

  const checkPassword = (e) => {
    setRePassword(e.target.value);
    if (e.target.value === password) {
      setRePasswordError(false);
      setRePasswordHelperText('');
      setPasswordError(false);
    } else {
      setRePasswordError(true);
      setRePasswordHelperText('Passwords do not match');
      setPasswordError(true);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      {!isRegistered ? (
        <>
          <Typography component="h1" variant="h5">
            Sign Up
          </Typography>
          {error && (
            <Typography color="error" variant="body2">
              {error}
            </Typography>
          )}
          <form noValidate onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="name"
              label="Name"
              name="name"
              autoComplete="name"
              autoFocus
              value={name}
              onChange={(e) => setName(e.target.value)}
              error={nameError}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              value={email}
              onChange={handleEmailChange}
              error={emailError}
              helperText={emailHelperText}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              error={usernameError}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={handlePasswordChange}
              error={passwordError}
              helperText={passwordHelperText}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="rePassword"
              label="Password again"
              type="password"
              id="rePassword"
              autoComplete="current-password"
              value={rePassword}
              onChange={checkPassword}
              error={rePasswordError}
              helperText={rePasswordHelperText}
            />
            <Box mt={2}>
              <Button type="submit" fullWidth variant="contained" color="primary">
                Sign Up
              </Button>
            </Box>
          </form>
          <Box mt={4}>
            <Typography component="h2" variant="h6" style={{ display: 'flex', alignItems: 'center' }}>
              <a href="/login" style={{ textDecoration: 'none', color: 'inherit', marginRight: '20px' }}>
                Already have an account?
              </a>
              <a href="/login" style={{ textDecoration: 'none', color: 'inherit', marginRight: '10px' }}>
                <Button fullWidth color="primary" variant="outlined" style={{ fontSize: '0.9rem' }}>
                  Sign in
                </Button>
              </a>
            </Typography>
          </Box>
        </>
      ) : (
        <Box mt={4} textAlign="center">
          <Typography component="h1" variant="h5">
            Verification Email Sent
          </Typography>
          <Typography variant="body2">
            A verification email has been sent to your email. Please click the link to activate your account. You will be redirected to the login page in 30 seconds.
          </Typography>
        </Box>
      )}
    </Container>
  );
};

export default Signup;
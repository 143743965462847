// src/pages/Profile.js
import React, { useState, useEffect, useContext } from 'react';
import { Container, Typography, Box, Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import axios from 'axios';
import AuthContext from '../context/AuthContext';
import { API_HOST } from '../config/config';
import ReactGA from 'react-ga4';

const Profile = () => {
  const { auth } = useContext(AuthContext);
  const [profile, setProfile] = useState({});
  const [openNameDialog, setOpenNameDialog] = useState(false);
  const [openPasswordDialog, setOpenPasswordDialog] = useState(false);
  const [newName, setNewName] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    // This will track the page view with the current path
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search});
  }, []);

  const fetchProfile = async () => {
    try {
      const response = await axios.get(`${API_HOST}/api/v1/profile/get_profile`, {
        headers: {
          'Authorization': `Bearer ${auth}`
        }
      });
      setProfile(response.data);
    } catch (error) {
      console.error('Error fetching profile:', error);
    }
  };

  const handleNameChange = async () => {
    if (newName === profile.name) {
      setError('New name cannot be the same as the old name.');
      return;
    }
    try {
      const response = await axios.put(`${API_HOST}/api/v1/profile/change_name`, JSON.stringify(newName), {
        headers: {
          'Authorization': `Bearer ${auth}`,
          'Content-Type': 'application/json'
        }
      });
      setMessage(response.data.message);
      setError('');
      fetchProfile();
      setOpenNameDialog(false);
    } catch (error) {
      if (error.response && error.response.data && error.response.data.detail) {
        setError(error.response.data.detail);
      } else {
        setError('Failed to update name. Please try again.');
      }
    }
  };

  const handlePasswordChange = async () => {
    if (oldPassword === newPassword) {
      setError('New password cannot be the same as the old password.');
      return;
    }
    try {
      const response = await axios.post(`${API_HOST}/api/v1/profile/change_password`, {
        old_password: oldPassword,
        new_password: newPassword
      }, {
        headers: {
          'Authorization': `Bearer ${auth}`,
          'Content-Type': 'application/json'
        }
      });
      setMessage(response.data.message);
      setError('');
      setOpenPasswordDialog(false);
    } catch (error) {
      if (error.response && error.response.data && error.response.data.detail) {
        setError(error.response.data.detail);
      } else {
        setError('Failed to update password. Please try again.');
      }
    }
  };

  useEffect(() => {
    fetchProfile();
  }, []);

  return (
    <Container component="main" maxWidth="sm">
      <Box mt={4}>
        <Typography component="h1" variant="h4" gutterBottom color="#ffffff">
          Profile
        </Typography>
        <Box mt={2}>
          <Typography component="h2" variant="h6" color="#ffffff">
            Name: <span style={{ color: '#90caf9' }}>{profile.name}</span>
          </Typography>
          <Typography component="h2" variant="h6" color="#ffffff">
            Email: <span style={{ color: '#90caf9' }}>{profile.email}</span>
          </Typography>
          <Typography component="h2" variant="h6" color="#ffffff">
            Username: <span style={{ color: '#90caf9' }}>{profile.username}</span>
          </Typography>
          <Typography component="h2" variant="h6" color="#ffffff">
            Created at: <span style={{ color: '#90caf9' }}>{profile['Created at']}</span>
          </Typography>
          <Typography component="h2" variant="h6" color="#ffffff">
            Updated at: <span style={{ color: '#90caf9' }}>{profile['Updated at']}</span>
          </Typography>
        </Box>
        {message && (
          <Typography color="primary" variant="body2">
            {message}
          </Typography>
        )}
        {error && (
          <Typography color="error" variant="body2">
            {error}
          </Typography>
        )}
        <Box mt={2}>
          <Button variant="contained" color="primary" onClick={() => setOpenNameDialog(true)} style={{ marginRight: '10px' }}>
            Edit Name
          </Button>
          <Button variant="contained" color="secondary" onClick={() => setOpenPasswordDialog(true)}>
            Change Password
          </Button>
        </Box>

        <Dialog open={openNameDialog} onClose={() => setOpenNameDialog(false)}>
          <DialogTitle>Edit Name</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter your new name.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              label="New Name"
              type="text"
              fullWidth
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenNameDialog(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleNameChange} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={openPasswordDialog} onClose={() => setOpenPasswordDialog(false)}>
          <DialogTitle>Change Password</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter your old password and new password.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              label="Old Password"
              type="password"
              fullWidth
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
            />
            <TextField
              margin="dense"
              label="New Password"
              type="password"
              fullWidth
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenPasswordDialog(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={handlePasswordChange} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Container>
  );
};

export default Profile;
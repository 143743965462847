// src/config/config.js
const config = {
    development: {
      API_HOST: "http://127.0.0.1:5000",
    },
    production: {
      API_HOST: "https://api.scopetech.ai",
    },
  };
  
  const environment = process.env.NODE_ENV || 'development';
  
  export const API_HOST = config[environment].API_HOST;
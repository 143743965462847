// src/pages/ImageRecommendation.js
import React, { useState, useContext, useEffect } from 'react';
import { Container, TextField, Button, Typography, Box, Grid, Card, CardMedia, CardContent, Slider, LinearProgress } from '@mui/material';
import axios from 'axios';
import AuthContext from '../context/AuthContext';
import { API_HOST } from '../config/config';
import ReactGA from 'react-ga4';

const GenerateRecommendation = () => {
  const { auth } = useContext(AuthContext);
  const [headline, setHeadline] = useState('');
  const [numRecommendations, setNumRecommendations] = useState('');
  const [similarity, setSimilarity] = useState(70);
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    // This will track the page view with the current path
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search});
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === 'headline') setHeadline(value);
    if (name === 'numRecommendations') setNumRecommendations(value);
  };

  const handleSimilarityChange = (event, newValue) => {
    setSimilarity(newValue);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError('');
    setResults([]);

    try {
      const similarityValue = similarity / 100; // Convert to a value between 0 and 1
      const response = await axios.post(
        `${API_HOST}/api/v1/ml/recommendations?headline=${headline}&n=${numRecommendations}&similarity=${similarityValue}`,
        '',
        {
          headers: {
            'Authorization': `Bearer ${auth}`,
            'accept': 'application/json'
          }
        }
      );

      setResults(response.data.results);
    } catch (err) {
      setError('Failed to fetch recommendations. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container component="main" maxWidth="md">
      <Box mt={4}>
        <Typography component="h1" variant="h4" gutterBottom color="#ffffff">
          Image Recommendations
        </Typography>
        {error && (
          <Typography color="error" variant="body2">
            {error}
          </Typography>
        )}
        <form onSubmit={handleSubmit}>
          <TextField
            name="headline"
            label="Image Description / Headline"
            value={headline}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            name="numRecommendations"
            label="Number of Recommendations"
            value={numRecommendations}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            required
          />
          <Box mt={2}>
            <Typography gutterBottom>Similarity: {similarity}%</Typography> 
            <Slider
              value={similarity}
              onChange={handleSimilarityChange}
              aria-labelledby="similarity-slider"
              valueLabelDisplay="auto"
              min={0}
              max={100}
              step={1}
              marks 
            />
          </Box>
          
          {/* <TextField
            name="similarity"
            label="Similarity %"
            value={similarity}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            required
          /> */}
          <Box mt={4} mb={4}>
            <Button type="submit" variant="contained" color="warning" fullWidth disabled={loading} sx={{ borderRadius: '20px', color: '#000000' }}>
              {loading ? 'Fetching...' : 'Get Recommendations'}
            </Button>
          </Box>
        </form>
        {loading && <LinearProgress />}
        {results.length > 0 && (
          <Box mt={4}>
            <Typography component="h2" variant="h6" gutterBottom color={'#26D608'}>
              Recommended Images
            </Typography>
            <Grid container spacing={2} justifyContent="center">
              {results.map((result, index) => (
                <Grid item key={index}>
                  <Card>
                    <CardMedia
                      component="img"
                      height="800"
                      image={`${API_HOST}/api/v1/ml/files/${result.file_path}`}
                      alt={result.description}
                    />
                    <CardContent>
                      <Typography variant="body1" color="textPrimary" component="p">
                        {result.description}
                      </Typography>
                      <Typography variant="body2" color="textSecondary" component="p">
                        {result.name}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default GenerateRecommendation;